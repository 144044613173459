import React from "react";
import { BarChart } from "@mui/icons-material";
import { Badge, IconButton, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { badgeClasses } from "@mui/material/Badge";
import { Link } from "react-router-dom";
import {
  Campaign,
  LoopFeedbackSummary,
  Team,
  User,
  Response,
} from "../../types";

const AlertBadge = styled(Badge)`
  & .${badgeClasses.badge} {
    top: -8px;
    right: -4px;
  }
`;

export interface Props {
  campaign: Campaign;
  team: Team;
  user: User;
  responses: Response[];
}

export const ResultsButton = ({ campaign, team, user, responses }: Props) => {
  const { loopFeedbackSummary } = campaign;
  const feedbackSummary = loopFeedbackSummary.find(
    (summary: LoopFeedbackSummary) => summary.subjectId === user.id,
  );

  // Find the response for this user
  const userResponse = responses.find(
    (response: Response) => response.authorId === user.id,
  );

  const showAlert =
    campaign.loopInstructor &&
    !feedbackSummary?.hasInstructorFeedback &&
    userResponse?.hasReceivedFeedback;

  const tooltipTitle = campaign.loopInstructor
    ? feedbackSummary?.hasInstructorFeedback
      ? "View Results"
      : userResponse?.hasReceivedFeedback
        ? "Instructor response needed"
        : "View Results"
    : "View Results";

  return (
    <Tooltip title={tooltipTitle}>
      <IconButton
        edge="end"
        aria-label="results"
        component={Link}
        to={`/results/${campaign.id}/PR/${team.id}/${user.id}`}
      >
        <BarChart />
        <AlertBadge
          badgeContent={"!"}
          color="primary"
          variant="dot"
          overlap="circular"
          invisible={!showAlert}
        />
      </IconButton>
    </Tooltip>
  );
};
