import React, { createContext, useContext } from "react";
import { gql, useQuery } from "@apollo/client";

interface User {
  id: string;
  firstName: string;
  lastName: string;
}

interface UserContextType {
  currentUser?: User;
  loading: boolean;
}

const UserContext = createContext<UserContextType>({ loading: true });

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    me {
      id
      firstName
      lastName
    }
  }
`;

interface GetCurrentUserResponse {
  me: User;
}

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { data, loading } = useQuery<GetCurrentUserResponse>(GET_CURRENT_USER);

  return (
    <UserContext.Provider value={{ currentUser: data?.me, loading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
