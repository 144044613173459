import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Paper,
  TextField,
  Typography,
  Alert,
} from "@mui/material";
import {
  GET_LOOP_RESPONSES,
  ADD_LOOP_RESPONSE,
  LoopResponsesResult,
} from "../../Queries";
import { useUser } from "../../Contexts/UserContext";

interface Props {
  campaignId: string;
  userId: string;
  resultsToken?: string;
  showStudent: boolean;
  showInstructor: boolean;
  hasRatings: boolean;
}

export const InstructorLoopResponse = ({
  campaignId,
  userId,
  resultsToken,
  showStudent,
  showInstructor,
  hasRatings,
}: Props) => {
  const [message, setMessage] = React.useState("");
  const { currentUser } = useUser();

  const { data: loopData, loading: loopLoading } =
    useQuery<LoopResponsesResult>(GET_LOOP_RESPONSES, {
      variables: { campaignId, subjectId: userId },
      context: {
        headers: resultsToken
          ? {
              Authorization: `JWT ${resultsToken}`,
            }
          : undefined,
      },
    });

  const [addLoopResponse] = useMutation(ADD_LOOP_RESPONSE, {
    refetchQueries: [
      {
        query: GET_LOOP_RESPONSES,
        variables: { campaignId, subjectId: userId },
        context: {
          headers: resultsToken
            ? {
                Authorization: `JWT ${resultsToken}`,
              }
            : undefined,
        },
      },
    ],
    context: {
      headers: resultsToken
        ? {
            Authorization: `JWT ${resultsToken}`,
          }
        : undefined,
    },
  });

  const handleSubmit = async () => {
    try {
      await addLoopResponse({
        variables: {
          campaignId,
          subjectId: userId,
          message,
        },
      });
      setMessage("");
    } catch (error) {
      console.error("Error submitting loop response:", error);
    }
  };

  if (loopLoading) return null;

  const instructorResponse = loopData?.loopResponses?.find(
    (r) => r.loopType === "IR",
  );
  const studentResponse = loopData?.loopResponses?.find(
    (r) => r.loopType === "SR",
  );

  return (
    <>
      <Typography variant="h6" sx={{ mt: 4 }}>
        Closing the Loop
      </Typography>
      {/* Student Response Section */}
      {showStudent && (
        <Paper sx={{ p: 3, mt: 2, mb: 2 }}>
          <Box>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: "bold" }}>
              Student Reflection
            </Typography>
            {studentResponse ? (
              <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
                {studentResponse.message}
              </Typography>
            ) : (
              <Typography
                variant="body1"
                sx={{
                  whiteSpace: "pre-wrap",
                  fontStyle: "italic",
                  color: "text.secondary",
                }}
              >
                This student has not yet provided a reflection.
              </Typography>
            )}
          </Box>
        </Paper>
      )}

      {/* Instructor Feedback Section */}
      {showInstructor && (
        <Paper sx={{ p: 3, mb: 4 }}>
          <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: "bold" }}>
            {instructorResponse ? (
              <>
                {instructorResponse.sender?.id === currentUser?.id ? (
                  "Your Feedback"
                ) : (
                  <>
                    Instructor Feedback
                    <Typography
                      component="span"
                      variant="body2"
                      sx={{
                        ml: 1,
                        fontWeight: "normal",
                        color: "text.secondary",
                      }}
                    >
                      from {instructorResponse.sender.firstName}{" "}
                      {instructorResponse.sender.lastName}
                    </Typography>
                  </>
                )}
              </>
            ) : (
              "Your Feedback"
            )}
          </Typography>
          {instructorResponse ? (
            <Box>
              <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
                {instructorResponse.message}
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography variant="body2" gutterBottom sx={{ mb: 2 }}>
                Please provide feedback on this student&apos;s results.
              </Typography>
              {!hasRatings ? (
                <Alert severity="info" sx={{ mb: 2 }}>
                  You&apos;ll be able to write a Closing the Loop response once
                  the student has received at least one rating.
                </Alert>
              ) : (
                <>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Enter your feedback..."
                    sx={{ mb: 2 }}
                    disabled={!hasRatings}
                  />
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={!message.trim() || !hasRatings}
                  >
                    Submit Feedback
                  </Button>
                </>
              )}
            </Box>
          )}
        </Paper>
      )}
    </>
  );
};
