import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  CircularProgress,
  Switch,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import {
  Section,
  Campaign as CampaignType,
  CampaignState as CampaignStateType,
  Template,
  Team as TeamType,
  User,
  Response,
  Team,
  ItemType,
  ItemSubjectType,
  CampaignGradingMode,
  GroupCategory,
  ItemRecipientType,
} from "../../types";
import { CampaignTeam } from "./Team";
import { MapList } from "../MapList";
import { TemplateSelector } from "../Template/Selector";
import { CampaignState } from "./State";
import {
  BarChart,
  Close,
  LockOpen,
  MoreVert,
  Preview,
  Send,
  Edit,
  Check,
  Grading,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { CampaignProgress } from "./Progress";
import { useTranslation } from "react-i18next";
import { AssignmentSelector } from "../Assignment/Selector";
import { AssignmentGradingSelector } from "../AssignmentGrading/Selector";
import { GroupCategorySelector } from "../GroupCategory/Selector";
import dayjs, { Dayjs } from "dayjs";
import advanced from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { RatingProportionSlider } from "../RatingProportion/Slider";

dayjs.extend(advanced);
dayjs.extend(timezone);
dayjs.extend(utc);

export interface Props {
  campaign: CampaignType;
  categories: GroupCategory[];
  responses: Response[];
  section?: Section;
  sections: Section[];
  teams: TeamType[];
  template?: Template;
  templates: Template[];
  users: User[];
  onChange: (campaign: CampaignType, activityLabel: string) => void;
  onDelete: (campaign: CampaignType) => void;
  onRemind: (campaign: CampaignType) => void;
  onExclude: (campaign: CampaignType, team: Team) => void;
  onSetGroupCategory: (
    campaign: CampaignType,
    groupcategory: GroupCategory | null,
  ) => void;
  onUpdateAssignmentPoints: (
    campaignId: string,
    assignmentPoints: string,
  ) => void;
  onPushGrades: () => void;
  isReleasing?: boolean;
  isPushingGrades?: boolean;
}

export const CampaignEdit = ({
  campaign,
  categories,
  onChange,
  onDelete,
  onRemind,
  onExclude,
  onSetGroupCategory,
  onUpdateAssignmentPoints,
  onPushGrades,
  responses,
  section,
  teams,
  template,
  templates,
  users,
  isReleasing = false,
  isPushingGrades = false,
}: Props) => {
  const [dueDateOpen, setDueDateOpen] = useState(false);
  const [closeDateOpen, setCloseDateOpen] = useState(false);
  const [sendDateOpen, setSendDateOpen] = useState(false);
  const [releaseDateOpen, setReleaseDateOpen] = useState(false);
  const [pointsEdit, setPointsEdit] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const actionMenuOpen = Boolean(anchorEl);
  const [t] = useTranslation();
  const theme = useTheme();
  const largerThanSm = useMediaQuery(theme.breakpoints.up("md"));

  const templateIds = templates.map((template) => template.id);
  const allTemplates =
    !template || templateIds.includes(template.id)
      ? templates
      : [template, ...templates];

  const noTemplate = !template;
  const hasAtLeastOneQuestion =
    !!template &&
    template.items.some(
      (item) =>
        item.itemType === ItemType.Choice || item.itemType === ItemType.Text,
    );
  const allTeamsHaveLeads = teams.every((team) => team.leaderId !== null);
  const hasTLQuestionWithoutLeads =
    !!template &&
    template.items.some(
      (item) =>
        item.recipientType === ItemRecipientType.TeamLeaders ||
        item.recipientType === ItemRecipientType.NonLeaders,
    ) &&
    !allTeamsHaveLeads;
  const hasNoTeams = teams.length === 0;
  const anyTeamHasNoMembers = teams.some((team) => team.userIds.length === 0);
  const hasLeadQuestionWithoutLeads =
    !!template &&
    template.items.some(
      (item) =>
        (item.subjectType === ItemSubjectType.LeadOnly ||
          item.subjectType === ItemSubjectType.LeadSelf) &&
        !allTeamsHaveLeads,
    );

  const sendBlockReason =
    (noTemplate && "Please select a template first.") ||
    (hasNoTeams && "No teams have been assigned to this survey.") ||
    (anyTeamHasNoMembers &&
      "One or more teams have no members assigned to them.") ||
    (!hasAtLeastOneQuestion &&
      "Selected template must include at least one question.") ||
    (hasLeadQuestionWithoutLeads &&
      "Selected template includes questions targeting team leads but one or more teams have no leader assigned.") ||
    (hasTLQuestionWithoutLeads &&
      "Selected template includes questions targeting team leaders or non-leaders but one or more teams have no leader assigned.") ||
    (campaign.assignmentEnabled &&
      (!campaign.assignmentPoints ||
        Number.parseInt(campaign.assignmentPoints) <= 0) &&
      "Assignment points must be set to a value greater than zero when assignments are enabled.") ||
    "";

  const isDeleteable = campaign.state === CampaignStateType.Draft;

  const handleActionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };

  function handleTemplateChange(template: Template) {
    onChange(
      { ...campaign, formId: template.id },
      t("campaigns.events.templateChange", { template: template.name }),
    );
  }

  function handleAssignmentChange(assignmentEnabled: boolean) {
    const assignmentPoints = campaign.assignmentPoints || "10";
    onChange(
      { ...campaign, assignmentEnabled, assignmentPoints },
      t("campaigns.events.assignmentChange", {
        assignment: assignmentEnabled ? "enabled" : "disabled",
      }),
    );
  }

  function handleAssignmentGradingChange(
    assignmentGradingMode: CampaignGradingMode,
  ) {
    onChange(
      { ...campaign, assignmentGradingMode },
      t("campaigns.events.assignmentGradingChange", {
        assignmentGradingMode: assignmentGradingMode,
      }),
    );
  }

  function handleAssignmentPointsChange(assignmentPoints: string) {
    onChange(
      { ...campaign, assignmentPoints },
      t("campaigns.events.assignmentPointsChange", {
        points: assignmentPoints,
      }),
    );
  }

  function handleAssignmentRatingProportionChange(value: number) {
    onChange(
      { ...campaign, assignmentRatingProportion: value },
      t("campaigns.events.assignmentRatingProportionChange", {
        proportion: value,
      }),
    );
  }

  function handleDueDateChange(dueDate: Dayjs) {
    const updates: Partial<CampaignType> = {
      dueDate: dueDate.toISOString(),
    };

    // Keep 1-hour buffer for send date
    if (
      campaign.autoSendDate &&
      dueDate.isBefore(dayjs(campaign.autoSendDate))
    ) {
      updates.autoSendDate = dueDate.subtract(1, "hour").toISOString();
    }

    // Minimal spacing for close date
    if (
      campaign.autoCloseDate &&
      !dayjs(campaign.autoCloseDate).isAfter(dueDate)
    ) {
      updates.autoCloseDate = dueDate.add(1, "minute").toISOString();

      // Minimal spacing for release date
      if (
        campaign.autoReleaseDate &&
        !dayjs(campaign.autoReleaseDate).isAfter(dayjs(updates.autoCloseDate))
      ) {
        updates.autoReleaseDate = dayjs(updates.autoCloseDate)
          .add(1, "minute")
          .toISOString();
      }
    }

    onChange(
      {
        ...campaign,
        ...updates,
      },
      t("campaigns.events.dueDateChange", {
        date: dueDate.toString(),
      }),
    );
  }

  function handleSendDateChange(sendDate: Dayjs | null) {
    const updates: Partial<CampaignType> = {
      autoSendDate: sendDate?.toISOString(),
    };

    if (sendDate) {
      // Keep 1-hour buffer for due date
      if (campaign.dueDate && !dayjs(campaign.dueDate).isAfter(sendDate)) {
        updates.dueDate = sendDate.add(1, "hour").toISOString();

        // Minimal spacing for close date
        if (
          campaign.autoCloseDate &&
          !dayjs(campaign.autoCloseDate).isAfter(dayjs(updates.dueDate))
        ) {
          updates.autoCloseDate = dayjs(updates.dueDate)
            .add(1, "minute")
            .toISOString();

          // Minimal spacing for release date
          if (
            campaign.autoReleaseDate &&
            !dayjs(campaign.autoReleaseDate).isAfter(
              dayjs(updates.autoCloseDate),
            )
          ) {
            updates.autoReleaseDate = dayjs(updates.autoCloseDate)
              .add(1, "minute")
              .toISOString();
          }
        }
      }
    }

    onChange(
      {
        ...campaign,
        ...updates,
      },
      t("campaigns.events.sendDateChange", {
        date: sendDate ? sendDate.toString() : "(disabled)",
      }),
    );
  }

  function handleCloseDateChange(closeDate: Dayjs | null) {
    const updates: Partial<CampaignType> = {
      autoCloseDate: closeDate ? closeDate.toISOString() : null,
    };

    if (closeDate) {
      // Minimal spacing for due date
      if (campaign.dueDate && dayjs(campaign.dueDate).isAfter(closeDate)) {
        updates.dueDate = closeDate.subtract(1, "minute").toISOString();

        // Keep 1-hour buffer between send and due date
        if (
          campaign.autoSendDate &&
          !dayjs(campaign.autoSendDate).isBefore(dayjs(updates.dueDate))
        ) {
          updates.autoSendDate = dayjs(updates.dueDate)
            .subtract(1, "hour")
            .toISOString();
        }
      }

      // Minimal spacing for release date
      if (
        campaign.autoReleaseDate &&
        !dayjs(campaign.autoReleaseDate).isAfter(closeDate)
      ) {
        updates.autoReleaseDate = closeDate.add(1, "minute").toISOString();
      }
    }

    onChange(
      {
        ...campaign,
        ...updates,
      },
      t("campaigns.events.closeDateChange", {
        date: closeDate ? closeDate.toString() : "(disabled)",
      }),
    );
  }

  function handleReleaseDateChange(releaseDate: Dayjs | null) {
    const updates: Partial<CampaignType> = {
      autoReleaseDate: releaseDate ? releaseDate.toISOString() : null,
    };

    if (releaseDate) {
      // Minimal spacing for close date
      if (
        campaign.autoCloseDate &&
        dayjs(campaign.autoCloseDate).isAfter(releaseDate)
      ) {
        updates.autoCloseDate = releaseDate.subtract(1, "minute").toISOString();

        // Minimal spacing for due date
        if (
          campaign.dueDate &&
          dayjs(campaign.dueDate).isAfter(dayjs(updates.autoCloseDate))
        ) {
          updates.dueDate = dayjs(updates.autoCloseDate)
            .subtract(1, "minute")
            .toISOString();

          // Keep 1-hour buffer between send and due date
          if (
            campaign.autoSendDate &&
            !dayjs(campaign.autoSendDate).isBefore(dayjs(updates.dueDate))
          ) {
            updates.autoSendDate = dayjs(updates.dueDate)
              .subtract(1, "hour")
              .toISOString();
          }
        }
      }
    }

    onChange(
      {
        ...campaign,
        ...updates,
      },
      t("campaigns.events.releaseDateChange", {
        date: releaseDate ? releaseDate.toString() : "(disabled)",
      }),
    );
  }

  function getTeamResponses(team: TeamType) {
    return responses.filter(
      // (response) => team.userIds.indexOf(response.authorId) >= 0
      (response) => response.groupId === team.id,
    );
  }

  function handleSendClick() {
    onChange(
      { ...campaign, state: CampaignStateType.Active },
      t("campaigns.events.send"),
    );
  }

  function handleScheduleClick() {
    onChange(
      { ...campaign, state: CampaignStateType.Scheduled },
      t("campaigns.events.schedule"),
    );
  }

  function handleReminderClick() {
    onRemind(campaign);
  }

  function handleDeleteClick() {
    onDelete(campaign);
  }

  function handleExcludeTeamClick(campaign: CampaignType, team: Team) {
    onExclude(campaign, team);
  }

  function handleEditAssignmentPoints() {
    setPointsEdit(true);
  }

  function handleUpdateAssignmentPoints(assignmentPoints: string) {
    setPointsEdit(false);
    onUpdateAssignmentPoints(campaign.id, assignmentPoints);
  }

  function setCampaignTitle(label: string) {
    if (label === campaign.name) {
      return;
    }
    onChange(
      { ...campaign, name: label },
      `Name Changed from ${campaign.name} to ${label}`,
    );
  }

  function handleCloseClick() {
    onChange(
      { ...campaign, state: CampaignStateType.Closed },
      t("campaigns.events.close"),
    );
  }

  function handleReopenClick() {
    onChange(
      { ...campaign, state: CampaignStateType.Active },
      t("campaigns.events.reopen"),
    );
  }

  function handleReleaseClick() {
    onChange(
      { ...campaign, released: new Date().toISOString() },
      t("campaigns.events.release"),
    );
  }

  function handleSetGroupCategoryClick(groupcategory: GroupCategory | null) {
    onSetGroupCategory(campaign, groupcategory);
  }

  function handlePushGradesClick() {
    onPushGrades();
  }

  function EditContent() {
    return (
      <MapList
        items={[
          {
            label: t("courses.course"),
            value: campaign.course.name,
          },
          // TEMPORARILY REMOVED for 337-multi-section-support
          // {
          //   label: t("sections.section"),
          //   value: (
          //     <SectionSelector
          //       sections={sections}
          //       selected={section}
          //       onChange={(section) => handleSectionChange(section)}
          //     />
          //   ),
          // },
          {
            label: t("templates.template"),
            value: (
              <TemplateSelector
                templates={allTemplates}
                selected={template}
                onChange={(template) => handleTemplateChange(template)}
              />
            ),
          },
          {
            label: t("campaigns.dueDate"),
            value: (
              <DesktopDateTimePicker
                open={dueDateOpen}
                onOpen={() => setDueDateOpen(true)}
                onClose={() => setDueDateOpen(false)}
                value={campaign.dueDate ? dayjs(campaign.dueDate) : undefined}
                onAccept={(date) => {
                  if (!date) return;
                  handleDueDateChange(date);
                }}
                slots={{
                  textField: (params) => (
                    <TextField
                      size="small"
                      fullWidth
                      {...params}
                      onClick={() => setDueDateOpen(true)}
                    />
                  ),
                }}
                slotProps={{
                  layout: {
                    sx: {
                      ul: {
                        "::-webkit-scrollbar": {
                          width: "2px",
                        },
                      },
                    },
                  },
                }}
              />
            ),
          },
          {
            label: t("campaigns.autoSendDate"),
            value: (
              <DesktopDateTimePicker
                open={sendDateOpen}
                onOpen={() => setSendDateOpen(true)}
                onClose={() => setSendDateOpen(false)}
                label={t("campaigns.autoSendDate")}
                value={
                  campaign.autoSendDate
                    ? dayjs(campaign.autoSendDate)
                    : undefined
                }
                onAccept={(date) => {
                  if (!date) return;
                  handleSendDateChange(date);
                }}
                slots={{
                  textField: (params) => (
                    <TextField
                      size="small"
                      fullWidth
                      {...params}
                      onClick={() => setSendDateOpen(true)}
                    />
                  ),
                  clearButton: () => (
                    <IconButton
                      onClick={(evt) => {
                        evt.stopPropagation();
                        handleSendDateChange(null);
                      }}
                    >
                      <Close />
                    </IconButton>
                  ),
                }}
                slotProps={{
                  layout: {
                    sx: {
                      ul: {
                        "::-webkit-scrollbar": {
                          width: "2px",
                        },
                      },
                    },
                  },
                  field: { clearable: true },
                }}
              />
            ),
          },
          {
            label: t("campaigns.autoCloseDate"),
            value: (
              <DesktopDateTimePicker
                open={closeDateOpen}
                onOpen={() => setCloseDateOpen(true)}
                onClose={() => setCloseDateOpen(false)}
                label={t("campaigns.autoCloseDate")}
                value={
                  campaign.autoCloseDate
                    ? dayjs(campaign.autoCloseDate)
                    : undefined
                }
                onAccept={(date) => {
                  if (!date) return;
                  handleCloseDateChange(date);
                }}
                slots={{
                  textField: (params) => (
                    <TextField
                      size="small"
                      fullWidth
                      {...params}
                      onClick={() => setCloseDateOpen(true)}
                    />
                  ),
                  clearButton: () => (
                    <IconButton
                      onClick={(evt) => {
                        evt.stopPropagation();
                        handleCloseDateChange(null);
                      }}
                    >
                      <Close />
                    </IconButton>
                  ),
                }}
                slotProps={{
                  layout: {
                    sx: {
                      ul: {
                        "::-webkit-scrollbar": {
                          width: "2px",
                        },
                      },
                    },
                  },
                  field: { clearable: true },
                }}
              />
            ),
          },
          {
            label: t("campaigns.autoReleaseDate"),
            value: (
              <DesktopDateTimePicker
                open={releaseDateOpen}
                onOpen={() => setReleaseDateOpen(true)}
                onClose={() => setReleaseDateOpen(false)}
                label={t("campaigns.autoReleaseDate")}
                value={
                  campaign.autoReleaseDate
                    ? dayjs(campaign.autoReleaseDate)
                    : undefined
                }
                onAccept={(date) => {
                  if (!date) return;
                  handleReleaseDateChange(date);
                }}
                slots={{
                  textField: (params) => (
                    <TextField
                      size="small"
                      fullWidth
                      {...params}
                      onClick={() => setReleaseDateOpen(true)}
                    />
                  ),
                  clearButton: () => (
                    <IconButton
                      onClick={(evt) => {
                        evt.stopPropagation();
                        handleReleaseDateChange(null);
                      }}
                    >
                      <Close />
                    </IconButton>
                  ),
                }}
                slotProps={{
                  layout: {
                    sx: {
                      ul: {
                        "::-webkit-scrollbar": {
                          width: "2px",
                        },
                      },
                    },
                  },
                  field: { clearable: true },
                }}
              />
            ),
          },
          {
            label: t("assignments.selector"),
            labelId: "assignment-select-label",
            value: (
              <AssignmentSelector
                selected={campaign.assignmentEnabled}
                onChange={(enabled) => handleAssignmentChange(enabled)}
              />
            ),
          },
          {
            label: t("assignmentsGrading.selector"),
            labelId: "assignment-grading-select-label",
            value: campaign.assignmentEnabled ? (
              <AssignmentGradingSelector
                selected={campaign.assignmentGradingMode}
                onChange={(gradingMode) =>
                  handleAssignmentGradingChange(gradingMode)
                }
              />
            ) : (
              "n/a"
            ),
          },
          {
            label: t("assignments.points"),
            value: campaign.assignmentEnabled ? (
              <TextField
                size="small"
                fullWidth
                defaultValue={campaign.assignmentPoints || ""}
                onBlur={(e) => handleAssignmentPointsChange(e.target.value)}
                type="number"
              />
            ) : (
              "n/a"
            ),
          },
          {
            label: t("assignments.ratingProportion"),
            value:
              campaign.assignmentEnabled &&
              campaign.assignmentGradingMode === "FA" ? (
                <RatingProportionSlider
                  initialValue={campaign.assignmentRatingProportion || 0}
                  onChangeCommitted={(value) =>
                    handleAssignmentRatingProportionChange(value)
                  }
                />
              ) : (
                "n/a"
              ),
          },
          {
            label: "Select group category",
            labelId: "category-select-label",
            value: (
              <GroupCategorySelector
                categories={categories}
                selected={campaign.groupcategory}
                onChange={(groupcategory) =>
                  handleSetGroupCategoryClick(groupcategory)
                }
              />
            ),
          },
          {
            label: t("campaigns.teamCount"),
            value: campaign.groups.length,
          },
          {
            label: t("campaigns.loopStudent"),
            value: (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box component="span" sx={{ mr: 1 }}>
                  No
                </Box>
                <Switch
                  checked={campaign.loopStudent}
                  onChange={(e) => {
                    onChange(
                      { ...campaign, loopStudent: e.target.checked },
                      t("campaigns.events.loopStudentChange", {
                        enabled: e.target.checked ? "enabled" : "disabled",
                      }),
                    );
                  }}
                />
                <Box component="span" sx={{ ml: 1 }}>
                  Yes
                </Box>
              </Box>
            ),
          },
          {
            label: t("campaigns.loopInstructor"),
            value: (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box component="span" sx={{ mr: 1 }}>
                  No
                </Box>
                <Switch
                  checked={campaign.loopInstructor}
                  onChange={(e) => {
                    onChange(
                      { ...campaign, loopInstructor: e.target.checked },
                      t("campaigns.events.loopInstructorChange", {
                        enabled: e.target.checked ? "enabled" : "disabled",
                      }),
                    );
                  }}
                />
                <Box component="span" sx={{ ml: 1 }}>
                  Yes
                </Box>
              </Box>
            ),
          },
        ]}
      />
    );
  }

  function ViewContent() {
    const now = dayjs();
    const canEditSendDate =
      campaign.state === CampaignStateType.Scheduled ||
      (campaign.autoSendDate && dayjs(campaign.autoSendDate).isAfter(now));
    const canEditCloseDate =
      campaign.state !== CampaignStateType.Closed &&
      (!campaign.autoCloseDate || dayjs(campaign.autoCloseDate).isAfter(now));
    const canEditReleaseDate =
      !campaign.released &&
      (!campaign.autoReleaseDate ||
        dayjs(campaign.autoReleaseDate).isAfter(now));

    return (
      <MapList
        items={[
          {
            label: t("courses.course"),
            value: campaign.course.name,
          },
          {
            label: t("sections.section"),
            value: section ? section.name : "All sections",
          },
          {
            label: t("templates.template"),
            value: template ? (
              <Link
                to={`/templates/${template.id}`}
                style={{ color: "inherit" }}
              >
                {template.name}
              </Link>
            ) : (
              "No template selected"
            ),
          },
          {
            label: t("campaigns.dueDate"),
            value: dayjs(campaign.dueDate).format("M/DD/YYYY h:mm A z"),
          },
          {
            label: t("campaigns.autoSendDate"),
            value: canEditSendDate ? (
              <DesktopDateTimePicker
                open={sendDateOpen}
                onOpen={() => setSendDateOpen(true)}
                onClose={() => setSendDateOpen(false)}
                label={t("campaigns.autoSendDate")}
                value={
                  campaign.autoSendDate
                    ? dayjs(campaign.autoSendDate)
                    : undefined
                }
                onAccept={(date) => {
                  if (!date) return;
                  handleSendDateChange(date);
                }}
                slots={{
                  textField: (params) => (
                    <TextField
                      size="small"
                      fullWidth
                      {...params}
                      onClick={() => setSendDateOpen(true)}
                    />
                  ),
                  clearButton: () => (
                    <IconButton
                      onClick={(evt) => {
                        evt.stopPropagation();
                        handleSendDateChange(null);
                      }}
                    >
                      <Close />
                    </IconButton>
                  ),
                }}
                slotProps={{
                  layout: {
                    sx: {
                      ul: {
                        "::-webkit-scrollbar": {
                          width: "2px",
                        },
                      },
                    },
                  },
                  field: { clearable: true },
                }}
              />
            ) : campaign.autoSendDate ? (
              dayjs(campaign.autoSendDate).format("M/DD/YYYY h:mm A z")
            ) : (
              "Not scheduled"
            ),
          },
          {
            label: t("campaigns.autoCloseDate"),
            value: canEditCloseDate ? (
              <DesktopDateTimePicker
                open={closeDateOpen}
                onOpen={() => setCloseDateOpen(true)}
                onClose={() => setCloseDateOpen(false)}
                label={t("campaigns.autoCloseDate")}
                value={
                  campaign.autoCloseDate
                    ? dayjs(campaign.autoCloseDate)
                    : undefined
                }
                onAccept={(date) => {
                  if (!date) return;
                  handleCloseDateChange(date);
                }}
                slots={{
                  textField: (params) => (
                    <TextField
                      size="small"
                      fullWidth
                      {...params}
                      onClick={() => setCloseDateOpen(true)}
                    />
                  ),
                  clearButton: () => (
                    <IconButton
                      onClick={(evt) => {
                        evt.stopPropagation();
                        handleCloseDateChange(null);
                      }}
                    >
                      <Close />
                    </IconButton>
                  ),
                }}
                slotProps={{
                  layout: {
                    sx: {
                      ul: {
                        "::-webkit-scrollbar": {
                          width: "2px",
                        },
                      },
                    },
                  },
                  field: { clearable: true },
                }}
              />
            ) : campaign.autoCloseDate ? (
              dayjs(campaign.autoCloseDate).format("M/DD/YYYY h:mm A z")
            ) : (
              "Not scheduled"
            ),
          },
          {
            label: t("campaigns.autoReleaseDate"),
            value: canEditReleaseDate ? (
              <DesktopDateTimePicker
                open={releaseDateOpen}
                onOpen={() => setReleaseDateOpen(true)}
                onClose={() => setReleaseDateOpen(false)}
                label={t("campaigns.autoReleaseDate")}
                value={
                  campaign.autoReleaseDate
                    ? dayjs(campaign.autoReleaseDate)
                    : undefined
                }
                onAccept={(date) => {
                  if (!date) return;
                  handleReleaseDateChange(date);
                }}
                slots={{
                  textField: (params) => (
                    <TextField
                      size="small"
                      fullWidth
                      {...params}
                      onClick={() => setReleaseDateOpen(true)}
                    />
                  ),
                  clearButton: () => (
                    <IconButton
                      onClick={(evt) => {
                        evt.stopPropagation();
                        handleReleaseDateChange(null);
                      }}
                    >
                      <Close />
                    </IconButton>
                  ),
                }}
                slotProps={{
                  layout: {
                    sx: {
                      ul: {
                        "::-webkit-scrollbar": {
                          width: "2px",
                        },
                      },
                    },
                  },
                  field: { clearable: true },
                }}
              />
            ) : campaign.autoReleaseDate ? (
              dayjs(campaign.autoReleaseDate).format("M/DD/YYYY h:mm A z")
            ) : (
              "Not scheduled"
            ),
          },
          {
            label: t("assignments.selector"),
            value: campaign.assignmentEnabled
              ? t("assignments.yes")
              : t("assignments.no"),
          },
          {
            label: t("assignmentsGrading.selector"),
            value: campaign.assignmentEnabled
              ? t(`assignmentsGrading.mode.${campaign.assignmentGradingMode}`)
              : "n/a",
          },
          {
            label: t("assignments.points"),
            value: campaign.assignmentEnabled ? (
              pointsEdit ? (
                <TextField
                  size="small"
                  defaultValue={campaign.assignmentPoints || ""}
                  onBlur={(e) => handleUpdateAssignmentPoints(e.target.value)}
                  type="number"
                />
              ) : (
                <>
                  <>{campaign.assignmentPoints}</>
                  {campaign.state === CampaignStateType.Active && (
                    <IconButton onClick={handleEditAssignmentPoints}>
                      <Edit />
                    </IconButton>
                  )}
                </>
              )
            ) : (
              "n/a"
            ),
          },
          {
            label: t("assignments.ratingProportion"),
            value:
              campaign.assignmentEnabled &&
              campaign.assignmentGradingMode === "FA"
                ? `${campaign.assignmentRatingProportion}% based on ratings, ${
                    100 - (campaign.assignmentRatingProportion || 0)
                  }% based on completion`
                : "n/a",
          },
          {
            label: t("campaigns.teamCount"),
            value: campaign.groups.length,
          },
          {
            label: t("campaigns.loopStudent"),
            value: campaign.loopStudent
              ? t("campaigns.yes")
              : t("campaigns.no"),
          },
          {
            label: t("campaigns.loopInstructor"),
            value: campaign.loopInstructor
              ? t("campaigns.yes")
              : t("campaigns.no"),
          },
        ]}
      />
    );
  }

  return (
    <>
      <Card>
        <CardHeader
          action={
            <>
              <CampaignState sx={{ ml: 2 }} campaign={campaign} />
              <span>&nbsp;</span>
              {largerThanSm ? (
                <>
                  {campaign.state !== CampaignStateType.Draft && (
                    <Tooltip title="View Results">
                      <IconButton
                        component={Link}
                        to={`/results/${campaign.id}//`}
                        sx={{ m: 1 }}
                        color="success"
                      >
                        <BarChart />
                      </IconButton>
                    </Tooltip>
                  )}
                  {campaign.state === CampaignStateType.Closed &&
                    (campaign.released === null ? (
                      <Tooltip
                        title={
                          campaign.autoReleaseDate
                            ? "Release Results Now"
                            : "Release Results"
                        }
                      >
                        <IconButton
                          sx={{ m: 1 }}
                          color="success"
                          onClick={handleReleaseClick}
                          disabled={isReleasing}
                        >
                          {isReleasing ? (
                            <CircularProgress size={24} />
                          ) : (
                            <Preview />
                          )}
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Results Released">
                        <span>
                          <IconButton
                            disabled
                            sx={{ m: 1 }}
                            onClick={handleReleaseClick}
                          >
                            <Check />
                          </IconButton>
                        </span>
                      </Tooltip>
                    ))}
                  {campaign.state === CampaignStateType.Closed &&
                    campaign.assignmentEnabled && (
                      <Tooltip
                        title={
                          campaign.gradesSyncedToLms
                            ? `Push grades to LMS (last pushed ${dayjs(
                                campaign.gradesSyncedToLms,
                              ).format("MM/DD/YYYY hh:mm A z")})`
                            : "Push Grades to LMS"
                        }
                      >
                        <IconButton
                          sx={{ m: 1 }}
                          color="success"
                          onClick={handlePushGradesClick}
                          disabled={isPushingGrades}
                        >
                          {isPushingGrades ? (
                            <CircularProgress size={24} />
                          ) : campaign.gradesSyncedToLms ? (
                            <Check />
                          ) : (
                            <Grading />
                          )}
                        </IconButton>
                      </Tooltip>
                    )}

                  {campaign.state === CampaignStateType.Draft &&
                    (sendBlockReason ? (
                      <Tooltip title={sendBlockReason}>
                        <span>
                          {campaign.autoSendDate ? (
                            <Button
                              variant="contained"
                              sx={{ m: 1 }}
                              endIcon={<Send />}
                              onClick={handleScheduleClick}
                              disabled
                            >
                              Schedule
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              sx={{ m: 1 }}
                              endIcon={<Send />}
                              onClick={handleSendClick}
                              disabled
                            >
                              Send
                            </Button>
                          )}
                        </span>
                      </Tooltip>
                    ) : campaign.autoSendDate ? (
                      <Button
                        variant="contained"
                        sx={{ m: 1 }}
                        endIcon={<Send />}
                        onClick={handleScheduleClick}
                      >
                        Schedule
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        sx={{ m: 1 }}
                        endIcon={<Send />}
                        onClick={handleSendClick}
                      >
                        Send
                      </Button>
                    ))}

                  {campaign.state === CampaignStateType.Scheduled && (
                    <Button
                      variant="contained"
                      sx={{ m: 1 }}
                      endIcon={<Send />}
                      onClick={handleSendClick}
                    >
                      Send Now
                    </Button>
                  )}

                  {campaign.state !== CampaignStateType.Draft &&
                    campaign.state !== CampaignStateType.Closed && (
                      <Button
                        variant="contained"
                        sx={{ m: 1 }}
                        endIcon={<Close />}
                        onClick={handleCloseClick}
                      >
                        Close Survey
                      </Button>
                    )}
                  <IconButton
                    onClick={handleActionClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={actionMenuOpen ? "campaign-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={actionMenuOpen ? "true" : undefined}
                  >
                    <MoreVert />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={actionMenuOpen}
                    onClose={handleActionClose}
                    onClick={handleActionClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{
                      horizontal: "right",
                      vertical: "top",
                    }}
                    anchorOrigin={{
                      horizontal: "right",
                      vertical: "bottom",
                    }}
                  >
                    {campaign.state === CampaignStateType.Active && (
                      <MenuItem onClick={handleReminderClick}>
                        <ListItemIcon>
                          <Send fontSize="small" />
                        </ListItemIcon>
                        Send Reminder
                      </MenuItem>
                    )}
                    {campaign.state === CampaignStateType.Closed && (
                      <MenuItem onClick={handleReopenClick}>
                        <ListItemIcon>
                          <LockOpen fontSize="small" />
                        </ListItemIcon>
                        Re-open Survey
                      </MenuItem>
                    )}
                    {isDeleteable && (
                      <MenuItem onClick={handleDeleteClick}>
                        <ListItemIcon>
                          <Close fontSize="small" />
                        </ListItemIcon>
                        Delete Survey
                      </MenuItem>
                    )}
                  </Menu>
                </>
              ) : (
                <>
                  <IconButton
                    onClick={handleActionClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={actionMenuOpen ? "campaign-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={actionMenuOpen ? "true" : undefined}
                  >
                    <MoreVert />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={actionMenuOpen}
                    onClose={handleActionClose}
                    onClick={handleActionClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    {campaign.state === CampaignStateType.Draft &&
                      (sendBlockReason ? (
                        <Tooltip title={sendBlockReason}>
                          <MenuItem disabled>
                            <ListItemIcon>
                              <Send fontSize="small" />
                            </ListItemIcon>
                            Send
                          </MenuItem>
                        </Tooltip>
                      ) : (
                        <MenuItem onClick={handleSendClick}>
                          <ListItemIcon>
                            <Send fontSize="small" />
                          </ListItemIcon>
                          Send
                        </MenuItem>
                      ))}
                    {campaign.state === CampaignStateType.Scheduled && (
                      <MenuItem onClick={handleSendClick}>
                        <ListItemIcon>
                          <Send fontSize="small" />
                        </ListItemIcon>
                        Send Now
                      </MenuItem>
                    )}
                    {campaign.state !== CampaignStateType.Draft &&
                      campaign.state !== CampaignStateType.Closed && (
                        <MenuItem onClick={handleCloseClick}>
                          <ListItemIcon>
                            <Close fontSize="small" />
                          </ListItemIcon>
                          Close Survey
                        </MenuItem>
                      )}
                    {campaign.state !== CampaignStateType.Draft && (
                      <MenuItem
                        component={Link}
                        to={`/results/${campaign.id}//`}
                      >
                        <ListItemIcon>
                          <BarChart fontSize="small" />
                        </ListItemIcon>
                        View Results
                      </MenuItem>
                    )}
                    {campaign.state === CampaignStateType.Closed &&
                      campaign.released === null && (
                        <MenuItem
                          onClick={handleReleaseClick}
                          disabled={isReleasing}
                        >
                          <ListItemIcon>
                            {isReleasing ? (
                              <CircularProgress size={24} />
                            ) : (
                              <Preview />
                            )}
                          </ListItemIcon>
                          {campaign.autoReleaseDate
                            ? "Release Results Now"
                            : "Release Results"}
                        </MenuItem>
                      )}
                    {campaign.state === CampaignStateType.Active && (
                      <MenuItem onClick={handleReminderClick}>
                        <ListItemIcon>
                          <Send fontSize="small" />
                        </ListItemIcon>
                        Send Reminder
                      </MenuItem>
                    )}
                    {campaign.state === CampaignStateType.Closed && (
                      <MenuItem onClick={handleReopenClick}>
                        <ListItemIcon>
                          <LockOpen fontSize="small" />
                        </ListItemIcon>
                        Re-open Survey
                      </MenuItem>
                    )}
                    {isDeleteable && (
                      <MenuItem onClick={handleDeleteClick}>
                        <ListItemIcon>
                          <Close fontSize="small" />
                        </ListItemIcon>
                        Delete Survey
                      </MenuItem>
                    )}
                    {campaign.state === CampaignStateType.Closed &&
                      campaign.assignmentEnabled && (
                        <MenuItem
                          onClick={handlePushGradesClick}
                          disabled={isPushingGrades}
                        >
                          <ListItemIcon>
                            {isPushingGrades ? (
                              <CircularProgress size={24} />
                            ) : campaign.gradesSyncedToLms ? (
                              <Check fontSize="small" />
                            ) : (
                              <Grading fontSize="small" />
                            )}
                          </ListItemIcon>
                          {campaign.gradesSyncedToLms
                            ? "Push Grades to LMS Again"
                            : "Push Grades to LMS"}
                        </MenuItem>
                      )}
                  </Menu>
                </>
              )}
            </>
          }
          title={
            <TextField
              variant="outlined"
              label={t("campaigns.label")}
              inputProps={{ maxLength: 255 }}
              defaultValue={campaign.name}
              fullWidth={true}
              size="small"
              onBlur={(e) => setCampaignTitle(e.target.value.trim())}
              onKeyDown={(e) => {
                if (e.key !== "Enter") return;
                e.preventDefault();
                setCampaignTitle(
                  (e.target as unknown as { value: string }).value.trim(),
                );
              }}
            />
          }
        />
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {campaign.state === CampaignStateType.Draft && <EditContent />}
            {campaign.state !== CampaignStateType.Draft && <ViewContent />}
            <CampaignProgress responses={responses} />
          </Box>
        </CardContent>
      </Card>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {teams.map((team) => (
          <Grid item key={team.id} xs={12} md={6} lg={4}>
            <CampaignTeam
              key={team.id}
              campaign={campaign}
              team={team}
              users={users}
              responses={getTeamResponses(team)}
              onExclude={handleExcludeTeamClick}
            />
          </Grid>
        ))}
        <Grid item xs={12} md={12} lg={12}>
          <Card>
            <CardHeader title="Activity" />
            <CardContent>
              <List>
                {campaign.activity.map((action) => (
                  <ListItem key={action.id}>
                    {dayjs(action.created).format("MM/DD/YYYY hh:mm A z")}
                    :&nbsp;
                    {action.label}
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
