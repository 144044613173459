import { InputLabel, Typography } from "@mui/material";
import React from "react";

export interface MappedItem {
  label: string;
  labelId?: string;
  value: React.ReactNode;
}

export interface Props {
  items: MappedItem[];
}

const tdStyle: React.CSSProperties = { paddingRight: 10, paddingTop: 10 };

export const MapList = ({ items }: Props) => {
  return (
    <table>
      {items.map((item, index) => {
        let label = <Typography>{item.label}:</Typography>;

        // If labelId is supplied, need to replace label
        if (item.labelId) {
          label = (
            <InputLabel id={item.labelId} sx={{ color: "text.primary" }}>
              {item.label}:
            </InputLabel>
          );
        }

        return (
          <tr key={index}>
            <td style={tdStyle}>{label}</td>
            <td style={tdStyle}>{item.value}</td>
          </tr>
        );
      })}
    </table>
  );
};
