import React from "react";
import { Link as LinkIcon } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { User, Response } from "../../types";

export interface Props {
  user: User;
  responses: Response[];
  responseAuthors: User[];
}

export const RespondButton = ({ responses, responseAuthors, user }: Props) => {
  function getResponse(user: User) {
    return responses.find((response) => user.id === response.authorId);
  }

  return (
    <Tooltip title="Respond">
      <IconButton
        edge="end"
        aria-label="response link"
        // component={Link}
        href={getResponse(user)?.tokenizedLink || ""}
        disabled={!responseAuthors.includes(user)}
      >
        <LinkIcon />
      </IconButton>
    </Tooltip>
  );
};
