import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      assignments: {
        selector: "Create as assignment",
        yes: "Yes",
        no: "No",
        points: "Max points",
        ratingProportion: "Grade weighting",
      },
      assignmentsGrading: {
        selector: "Grade this assignment",
        fixedAutomatic: "Auto graded",
        manual: "Grade manually in the LMS",
        mode: {
          FA: "Auto graded",
          MA: "Grade manually in the LMS",
        },
      },
      campaigns: {
        add: "Add Survey",
        autoCloseDate: "Scheduled close",
        autoReleaseDate: "Scheduled results release",
        autoSendDate: "Scheduled send",
        campaign: "Survey",
        confirmDelete: "Are you sure you want to delete {{name}} survey?",
        dueDate: "Due date",
        events: {
          assignmentChange: "Assignment creation {{assignment}}",
          assignmentGradingChange:
            "Assignment grading changed to {{assignmentGradingMode}}",
          assignmentPointsChange: "Assignment points changed to {{points}}",
          assignmentRatingProportionChange:
            "Assignment award weighting changed to {{assignmentRatingProportion}}",
          close: "Survey Closed",
          closeDateChange: "Scheduled close date changed to {{date}}",
          dueDateChange: "Due date changed to {{date}}",
          loopStudentChange: "Closing the loop with students {{enabled}}",
          loopInstructorChange: "Closing the loop with instructors {{enabled}}",
          schedule: "Survey Scheduled",
          send: "Survey Sent",
          sendDateChange: "Scheduled send date changed to {{date}}",
          reopen: "Survey Reopened",
          release: "Survey Results Released",
          releaseDateChange: "Scheduled release date changed to {{date}}",
          sectionChange: "Section changed to {{section}}",
          templateChange: "Library changed to {{template}}",
        },
        label: "Survey name",
        teamCount: "Teams included",
        loopInstructor: "Close the loop with instructors",
        loopStudent: "Close the loop with students",
        yes: "Yes",
        no: "No",
      },
      courses: {
        course: "Course",
      },
      sections: {
        section: "Section",
      },
      templates: {
        add: "Add Template",
        global: "Feedback Loop templates",
        institution: "Institution shared templates",
        label: "Template Name",
        template: "Template",
        your: "Your templates",
        snapshot:
          "You're viewing a snapshot of this template as it was sent with a survey.",
      },
      categories: {
        category: "Group Category",
        label: "Group Category Name",
      },
      breadcrumbs: {
        campaigns: "Surveys",
        yourSurveys: "Your Surveys",
        templates: "Library",
        teams: "Teams",
        teamList: "Get Started",
      },
      navigation: {
        campaigns: "Surveys",
        teams: "Teams",
        templates: "Library",
      },
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
