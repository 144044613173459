import React from "react";
import { Add, Check, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  FormItem,
  ItemOption,
  ItemRecipientType,
  ItemSubjectType,
  ItemType,
  ItemVisibility,
  ItemType as QuestionTypeEnum,
  Template,
  PointsAllocationMode,
} from "../../types";
import {
  MDXEditor,
  toolbarPlugin,
  listsPlugin,
  headingsPlugin,
  thematicBreakPlugin,
  quotePlugin,
  linkPlugin,
  BoldItalicUnderlineToggles,
  BlockTypeSelect,
  CreateLink,
  ListsToggle,
  InsertThematicBreak,
  linkDialogPlugin,
} from "@mdxeditor/editor";
import { getLabelWithRemainingChars } from "../../Helpers";
import randomUUID from "../../UUID";
import { TypeSelect } from "./Types";

import "@mdxeditor/editor/style.css";

const showOptionalSelector = false;

export interface Props {
  formItem: FormItem;
  onChange: (value: FormItem) => void;
  onDoneClick: (value: FormItem) => void;
  onDeleteClick?: () => void;
  template: Template;
}

export const QuestionEdit = ({
  formItem: question,
  template,
  onChange,
  onDoneClick,
  onDeleteClick,
}: Props) => {
  function handleLabelChange(label: string) {
    onChange({ ...question, label });
  }

  function handleSubjectTypeChange(subjectType: ItemSubjectType) {
    onChange({ ...question, subjectType });
  }

  function handleRecipientTypeChange(recipientType: ItemRecipientType) {
    onChange({ ...question, recipientType });
  }

  function handleQuestionTypeChange(itemType: ItemType, options: ItemOption[]) {
    onChange({ ...question, itemType, options });
  }

  function handleVisibilityChange(visibility: ItemVisibility) {
    onChange({ ...question, visibility });
  }

  function handleScaleChange(scale: number) {
    onChange({ ...question, scale });
  }

  function handleVarianceChange(minVariance: number) {
    onChange({ ...question, minVariance });
  }

  function handleOptionLabelChange(id: string, label: string) {
    const clone = { ...question };
    clone.options = clone.options.map((target) =>
      target.id === id ? { ...target, label } : target,
    );
    onChange(clone);
  }

  function handleOptionValueChange(id: string, value: number) {
    const clone = { ...question };
    clone.options = clone.options.map((target) =>
      target.id === id ? { ...target, value } : target,
    );
    onChange(clone);
  }

  function handleOptionDelete(id: string) {
    const clone = { ...question };
    clone.options = clone.options.filter((target) => target.id !== id);
    onChange(clone);
  }

  function handleOptionAdd() {
    const clone = { ...question };
    const maxOrder = Math.max(...clone.options.map((o) => o.order), 0);
    clone.options = [
      ...clone.options,
      { id: randomUUID(), label: "New Option", value: 3, order: maxOrder + 1 },
    ];
    onChange(clone);
  }

  function handleOptionalChange(optional: boolean) {
    const clone = { ...question };
    clone.optional = optional;
    onChange(clone);
  }

  function hasError(formItem: FormItem, itemOption: ItemOption) {
    return Boolean(
      formItem.options.find(
        (x) => x.id !== itemOption.id && x.value === itemOption.value,
      ),
    );
  }

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row", mt: 1, mb: 3 }}>
        <FormControl fullWidth margin="normal" size="small" sx={{ m: 0 }}>
          <InputLabel id={`${question.id}-type`}>Question Type</InputLabel>

          <TypeSelect
            labelId={`${question.id}-type`}
            id={`${question.id}-typeSelect`}
            label="Question Type"
            size="small"
            onTypeSelect={handleQuestionTypeChange}
            item={question}
            template={template}
          />
        </FormControl>
        {showOptionalSelector && (
          <FormControlLabel
            sx={{ ml: 2 }}
            control={
              <Checkbox
                onChange={(e) => handleOptionalChange(e.target.checked)}
              />
            }
            label="Optional"
          />
        )}
      </Box>

      {question.itemType === QuestionTypeEnum.Instructions ? (
        <>
          {" "}
          <InputLabel sx={{ mb: 1 }}>
            {getLabelWithRemainingChars(
              "Instructions",
              question.label.length,
              1024,
            )}
            :
          </InputLabel>
          <Box sx={{ marginBottom: 6, bgcolor: "#1c1c1c", borderRadius: 1 }}>
            <MDXEditor
              className="dark-theme dark-editor"
              markdown={question.label}
              onChange={(value) => handleLabelChange(value || "")}
              plugins={[
                headingsPlugin(),
                listsPlugin(),
                thematicBreakPlugin(),
                quotePlugin(),
                linkPlugin(),
                linkDialogPlugin(),
                toolbarPlugin({
                  toolbarContents: () => (
                    <>
                      <BlockTypeSelect />
                      <BoldItalicUnderlineToggles />
                      <CreateLink />
                      <ListsToggle options={["bullet", "number"]} />
                      <InsertThematicBreak />
                    </>
                  ),
                }),
              ]}
            />
          </Box>
        </>
      ) : (
        <TextField
          label={getLabelWithRemainingChars(
            "Question Label",
            question.label.length,
            1024,
          )}
          error={!question.label.length}
          key={`${question.id}-label-edit`}
          multiline
          value={question.label}
          autoFocus={true}
          placeholder="Enter your survey question here"
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: 1024 }}
          onBlur={(e) => handleLabelChange(e.target.value.trim())}
          onChange={(e) => handleLabelChange(e.target.value)}
          fullWidth={true}
          size="small"
          sx={{ mb: 3 }}
        />
      )}

      <FormControl fullWidth margin="normal" size="small" sx={{ m: 0, mb: 3 }}>
        <InputLabel id={`${question.id}-recipientType`}>Recipients</InputLabel>

        <Select
          labelId={`${question.id}-recipientType`}
          id={`${question.id}-recipientTypeSelect`}
          value={question.recipientType}
          label="Recipients"
          size="small"
          onChange={(e) =>
            handleRecipientTypeChange(e.target.value as ItemRecipientType)
          }
        >
          <MenuItem value={ItemRecipientType.TeamMembers}>
            All team members
          </MenuItem>
          <MenuItem value={ItemRecipientType.TeamLeaders}>
            Team leaders only
          </MenuItem>
          <MenuItem value={ItemRecipientType.NonLeaders}>
            Everyone but leads
          </MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal" size="small" sx={{ m: 0, mb: 3 }}>
        <InputLabel id={`${question.id}-subjectType`}>
          {question.itemType === QuestionTypeEnum.Instructions
            ? "Placement"
            : "Subject type"}
        </InputLabel>

        <Select
          labelId={`${question.id}-subjectType`}
          id={`${question.id}-subjecTypeSelect`}
          value={question.subjectType}
          label={
            question.itemType === QuestionTypeEnum.Instructions
              ? "Placement"
              : "Subject type"
          }
          size="small"
          onChange={(e) =>
            handleSubjectTypeChange(e.target.value as ItemSubjectType)
          }
          disabled={question.itemType === ItemType.Points}
        >
          <MenuItem value={ItemSubjectType.PeerSelf}>
            Peer and self-feedback
          </MenuItem>
          <MenuItem value={ItemSubjectType.PeerOnly}>
            Peer feedback only
          </MenuItem>
          <MenuItem value={ItemSubjectType.LeadSelf}>
            Team leads and self
          </MenuItem>
          <MenuItem value={ItemSubjectType.LeadOnly}>Team leads only</MenuItem>
          <MenuItem value={ItemSubjectType.NonLeadSelf}>
            Non-leads and self
          </MenuItem>
          <MenuItem value={ItemSubjectType.NonLeadOnly}>
            Non-leads only
          </MenuItem>
          <MenuItem value={ItemSubjectType.General}>
            General feedback (results only visible to instructors)
          </MenuItem>
        </Select>
      </FormControl>

      {question.itemType !== QuestionTypeEnum.Instructions && (
        <FormControl
          fullWidth
          margin="normal"
          size="small"
          sx={{ m: 0, mb: 3 }}
          disabled={
            (question.subjectType !== ItemSubjectType.PeerSelf &&
              question.subjectType !== ItemSubjectType.PeerOnly) ||
            question.itemType === ItemType.Points
          }
        >
          <InputLabel id={`${question.id}-visibility`}>
            Result visibility
          </InputLabel>

          <Select
            labelId={`${question.id}-visibility`}
            id={`${question.id}-visibilitySelect`}
            value={question.visibility}
            label="Result visibility"
            size="small"
            onChange={(e) =>
              handleVisibilityChange(e.target.value as ItemVisibility)
            }
          >
            <MenuItem value={ItemVisibility.Releasable}>
              Anonymized results releasable to students
            </MenuItem>
            <MenuItem value={ItemVisibility.Private}>
              Results private to instructor
            </MenuItem>
          </Select>
        </FormControl>
      )}
      {question.itemType == QuestionTypeEnum.Choice && (
        <Table
          sx={{
            width: "auto",
            ".MuiTableCell-root": { padding: 0, pr: 2, pt: 1, border: 0 },
          }}
        >
          <TableHead
            sx={{
              border: 0,
              backgroundColor: "rgba(255, 255, 255, 0.09)",
              ".MuiTableCell-root": { p: 1 },
            }}
          >
            <TableRow>
              <TableCell>Label</TableCell>
              <TableCell>Internal Value</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          {[...question.options]
            .sort((a, b) => a.order - b.order)
            .map((option, index) => {
              const error = hasError(question, option);
              return (
                <TableRow key={index}>
                  <TableCell>
                    <TextField
                      inputProps={{ maxLength: 255 }}
                      value={option.label}
                      onBlur={(e) =>
                        handleOptionLabelChange(
                          option.id,
                          e.target.value.trim(),
                        )
                      }
                      onChange={(e) =>
                        handleOptionLabelChange(option.id, e.target.value)
                      }
                      size="small"
                      helperText={error && <>&nbsp;</>}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      sx={{ ml: 1 }}
                      type="number"
                      value={option.value}
                      onBlur={(e) =>
                        handleOptionValueChange(
                          option.id,
                          parseInt(e.target.value.trim()),
                        )
                      }
                      onChange={(e) =>
                        handleOptionValueChange(
                          option.id,
                          parseInt(e.target.value),
                        )
                      }
                      size="small"
                      error={error}
                      helperText={error && "Unique value required"}
                    />
                  </TableCell>

                  <TableCell>
                    <Button
                      variant="contained"
                      sx={{ ml: 1 }}
                      onClick={() => handleOptionDelete(option.id)}
                      size="small"
                    >
                      Delete <Delete />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
        </Table>
      )}
      {question.itemType == QuestionTypeEnum.Choice && (
        <Button
          variant="contained"
          sx={{ m: 1 }}
          onClick={() => handleOptionAdd()}
          size="small"
        >
          Add Option <Add />
        </Button>
      )}
      {question.itemType == QuestionTypeEnum.Points && (
        <>
          <TextField
            label="Points per Student (baseline)"
            fullWidth
            sx={{ mt: 1, mb: 2 }}
            type="number"
            value={question.scale ?? 100}
            onBlur={(e) => handleScaleChange(parseInt(e.target.value.trim()))}
            onChange={(e) => handleScaleChange(parseInt(e.target.value))}
            size="small"
          />

          <Box
            sx={{ display: "flex", alignItems: "center", width: "100%", mb: 2 }}
          >
            <span>Unique Allocation</span>
            <FormControlLabel
              sx={{ mx: 2 }}
              control={
                <Switch
                  checked={
                    question.pointsAllocationMode ===
                    PointsAllocationMode.MinimumVariance
                  }
                  onChange={(e) =>
                    onChange({
                      ...question,
                      pointsAllocationMode: e.target.checked
                        ? PointsAllocationMode.MinimumVariance
                        : PointsAllocationMode.UniqueAllocation,
                      minVariance: !e.target.checked
                        ? undefined
                        : question.minVariance,
                    })
                  }
                />
              }
              label=""
            />
            <span>Minimum Variance</span>
          </Box>

          {question.pointsAllocationMode ===
            PointsAllocationMode.MinimumVariance && (
            <TextField
              label="Minimum Variance"
              fullWidth
              sx={{ mt: 1, mb: 2 }}
              type="number"
              value={question.minVariance ?? 0}
              onBlur={(e) =>
                handleVarianceChange(parseInt(e.target.value.trim()))
              }
              onChange={(e) => handleVarianceChange(parseInt(e.target.value))}
              size="small"
            />
          )}
        </>
      )}
      {onDeleteClick && (
        <Button
          variant="contained"
          sx={{ m: 1 }}
          onClick={() => onDeleteClick()}
          size="small"
        >
          Delete Question <Delete />
        </Button>
      )}
      <Button
        variant="contained"
        sx={{ m: 1 }}
        onClick={() => onDoneClick(question)}
        size="small"
      >
        Finish Editing <Check />
      </Button>
    </>
  );
};
