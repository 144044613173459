import React from "react";
import { Preview } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { Campaign, User } from "../../types";

export interface Props {
  campaign: Campaign;
  user: User;
}

export const PreviewButton = ({ campaign, user }: Props) => {
  return (
    <Tooltip title="Preview as Student">
      <IconButton
        edge="end"
        aria-label="result preview"
        component={Link}
        to={`/results/preview/${campaign.id}/${user.id}/campaign`}
      >
        <Preview />
      </IconButton>
    </Tooltip>
  );
};
