import React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface Props {
  selected?: boolean;
  onChange: (assignmentEnabled: boolean) => void;
}

export const AssignmentSelector = ({ selected, onChange }: Props) => {
  const [t] = useTranslation();
  return (
    <FormControl size="small" fullWidth>
      <Select
        labelId="assignment-select-label"
        id="assignment-select"
        value={selected}
        onChange={(e) => {
          const target = e.target.value === "true";
          onChange(target);
        }}
      >
        <MenuItem key={0} value={"false"}>
          {t("assignments.no")}
        </MenuItem>
        <MenuItem key={1} value={"true"}>
          {t("assignments.yes")}
        </MenuItem>
      </Select>
    </FormControl>
  );
};
