import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Paper,
  TextField,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  GET_LOOP_RESPONSES,
  ADD_LOOP_RESPONSE,
  LoopResponsesResult,
} from "../../Queries";
import { LoopResponse } from "../../types";
import { Edit as EditIcon } from "@mui/icons-material";

interface Props {
  campaignId: string;
  userId: string;
  resultsToken?: string;
  showStudent: boolean;
  showInstructor: boolean;
  preview?: boolean;
}

export const StudentLoopResponse = ({
  campaignId,
  userId,
  resultsToken,
  showStudent,
  showInstructor,
  preview = false,
}: Props) => {
  const [message, setMessage] = React.useState("");
  const [isEditing, setIsEditing] = React.useState(false);

  const { data, loading } = useQuery<LoopResponsesResult>(GET_LOOP_RESPONSES, {
    variables: { campaignId, subjectId: userId },
    context: {
      headers: resultsToken
        ? {
            Authorization: `JWT ${resultsToken}`,
          }
        : undefined,
    },
  });

  const [addLoopResponse] = useMutation(ADD_LOOP_RESPONSE, {
    refetchQueries: [
      {
        query: GET_LOOP_RESPONSES,
        variables: { campaignId, subjectId: userId },
        context: {
          headers: resultsToken
            ? {
                Authorization: `JWT ${resultsToken}`,
              }
            : undefined,
        },
      },
    ],
    context: {
      headers: resultsToken
        ? {
            Authorization: `JWT ${resultsToken}`,
          }
        : undefined,
    },
  });

  const handleSubmit = async () => {
    try {
      await addLoopResponse({
        variables: {
          campaignId,
          subjectId: userId,
          message,
        },
      });
      setMessage("");
      setIsEditing(false);
    } catch (error) {
      console.error("Error submitting loop response:", error);
    }
  };

  // Set message to existing response when editing is enabled
  React.useEffect(() => {
    if (isEditing && data?.loopResponses) {
      const studentResponse = data.loopResponses.find(
        (r) => r.loopType === "SR",
      );
      if (studentResponse) {
        setMessage(studentResponse.message);
      }
    }
  }, [isEditing, data?.loopResponses]);

  if (loading) return null;

  const instructorResponse = data?.loopResponses?.find(
    (r) => r.loopType === "IR",
  );
  const studentResponse = data?.loopResponses?.find((r) => r.loopType === "SR");

  return (
    <>
      <Typography variant="h6" sx={{ mt: 0.5 }}>
        Reflecting on your results
      </Typography>
      {/* Instructor Feedback Section */}
      {showInstructor && instructorResponse && (
        <Paper sx={{ p: 3, mt: 4, mb: 2 }}>
          <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: "bold" }}>
            Instructor Feedback
            {instructorResponse.sender && (
              <Typography
                component="span"
                variant="body2"
                sx={{ ml: 1, fontWeight: "normal", color: "text.secondary" }}
              >
                from {instructorResponse.sender.firstName}{" "}
                {instructorResponse.sender.lastName}
              </Typography>
            )}
          </Typography>
          <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
            {instructorResponse.message}
          </Typography>
        </Paper>
      )}

      {/* Student Response Section */}
      {showStudent && (
        <Paper sx={{ p: 3, mb: 4 }}>
          {studentResponse && !isEditing ? (
            <Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  Your Reflection
                </Typography>
                {/* <IconButton
                  size="small"
                  onClick={() => setIsEditing(true)}
                  sx={{ ml: 1 }}
                >
                  <EditIcon fontSize="small" />
                </IconButton> */}
              </Box>
              <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
                {studentResponse.message}
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography variant="body2" gutterBottom>
                Please take a moment to reflect on your feedback results and
                share your thoughts.
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Enter your reflection..."
                sx={{ mb: 2 }}
              />
              <Box sx={{ display: "flex", gap: 2 }}>
                <Tooltip
                  title={
                    preview
                      ? "Student reflection cannot be submitted while in preview mode"
                      : ""
                  }
                  arrow
                >
                  <span>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      disabled={!message.trim() || preview}
                    >
                      Submit Reflection
                    </Button>
                  </span>
                </Tooltip>
                {isEditing && (
                  <Button
                    variant="contained"
                    onClick={() => setIsEditing(false)}
                  >
                    Cancel
                  </Button>
                )}
              </Box>
            </Box>
          )}
        </Paper>
      )}
    </>
  );
};
